import React from "react";
import {
  Switch,
  Route,
} from "react-router-dom";
import SignIn from '../components/pages/sign_in/SignIn';
import SignUp from '../components/pages/sign_up/SignUp';
import PageInfo from '../components/pages/page_info/page_info';
import SelectRedirect from '../components/pages/select_allowed_routes/select_redirect_route';
import TestPage from "../components/pages/test_page/test_page";

const AppRouter = (props) => {

return (
        <Switch>
            <Route path="/" exact  >
                <SignIn/>
            </Route>
          <Route path="/register" >
              <SignUp/>
        </Route>
            <Route path="/page_info" >
                <PageInfo/>
            </Route>
            <Route path="/test_page" >
                <TestPage/>
            </Route>
            <Route path="/select_page" >
                <SelectRedirect/>
            </Route>
        </Switch>
);}

export default AppRouter;



