import React, { useState } from "react";
import BoxCenter from "../../wrap_components/global_wrappers/main_wrapper";
import { useDispatch, useSelector } from 'react-redux';
import SignUpForm from './form/SignUpForm';
import Cookies from 'js-cookie';
import moment from "moment";
import sha1 from 'sha1';
import OfflineDialog from '../../modals/offline_modal/offlineModal';
import {glv} from "../../../App";
import {signUp} from "../../../redux/actions/authActions/auth.actions";
import {Notifications_History_Context} from "../../wrap_components/notification_wrapper/notifications_wrapper";
import {useHistory} from "react-router-dom";


const SignUp = (props) => {
  let history = useHistory();
  const [showToast, close_all_notification] = React.useContext(Notifications_History_Context);
  const [errorShow, setErrorShow] = useState("");
  const [internetConnDi, setInternetConnDi] = useState(false);
  const localization = useSelector((state) => state.authReducer.lang_values);
  const language = useSelector(state => state.authReducer.language);
  const isConfirm = useSelector(state => state.authReducer.isConfirm);
  const dispatch = useDispatch();

  const register = (user ) => {
    if(!navigator.onLine) {
      let login = Cookies.get("hash_key",{domain: '.bregus.eu'});
      let errforLog = {
        timestamp: moment().unix(),
        login_hash: login ? login : "",
        point_error: "try register",
        error_msg: "Internet connection error",
      };
      let logos = JSON.parse(localStorage.getItem("logos"));
      logos.arr.push(errforLog);
      localStorage.setItem(
        "logos",
        JSON.stringify({ key: sha1(logos.arr), arr: logos.arr })
      );
      setInternetConnDi(true);
      return;
    }
      signUp(user, history, showToast, dispatch)
  }

  return language && localization ?  (
    <BoxCenter logIntoReg={()=>history.push('/')}  setInternetConnDi={setInternetConnDi}>

      {!isConfirm ? (
          <h1
              className={'not_confirmed_text'}
          >
            {
              glv(`confirm_cookie.confirm_cookieErrors.${'notConfirmed'}`)

            }
          </h1>
      ) : null}

      {errorShow ? (
          <h1
              className={`status_text error`}
          >
            {errorShow}
          </h1>
      ) : null}

      {
        glv(`register.titles.registerTitle`)!== `register.titles.registerTitle` ?

<h1
className={'register_title'}
>
{
  glv(`register.titles.registerTitle`)
}
</h1> : null
      }
      {
        internetConnDi ?
            < OfflineDialog
            open={internetConnDi}
            close={()=>setInternetConnDi(!internetConnDi)}
        /> : null
      }

      <SignUpForm
        props={props}
        handleSubmit={(data) => register(data)}
        errSh={setErrorShow}
      />


    </BoxCenter>
   ) : null;


}

export default SignUp;
