import AuthService from "../../../services/auth.service";
import * as actionTypes from './actions.types';
import Cookies from 'js-cookie';
import {glv, setlogos} from "../../../App";




export const setLangValues = (lang_values) => {
  return {
    type: actionTypes.LANG_VALUES,
    lang_values,
  };
};

export const setLangConf = (lang_conf) => {
  return {
    type: actionTypes.LANG_CONF,
    lang_conf,
  };
};

export function activateUser( hist, hash) {
    return async (dispatch) => await AuthService.activateUser(hash)
}


export const setIsConfirm = (isConfirm) => {
  return {
    type: actionTypes.IS_CONFIRM,
    isConfirm,
  };
};

export const setIsLoading = (is_loading) => {
    return {
        type: actionTypes.IS_LOADING,
        is_loading,
    };
};


export const setLanguage = (language) => {

  Cookies.set('language', language, { domain: '.bregus.eu', sameSite: 'Strict'});
  return {
    type: actionTypes.SET_LANGUAGE,
    language,
  };
};

export const setOpenRedirectFrame = (status) => {


  return {
    type: actionTypes.REDIR_FRAME_OP,
    redir_frame_status: status,
  };
};

const cutted_password = (password)=>password.slice(0, 4)

const conditions_codes = (
    code,
    user,  set_error, shFP,
) => {
    if(code ===404){
        setlogos(
            glv(`login.loginErrors.err404`  ) + ' login:' + user.login, "login form");
        set_error(glv(`login.loginErrors.err404`  ), 'error', `: ${user.login}`);
        return;
    }
    if(code ===401){
        setlogos(
            glv(`login.loginErrors.err401`  ) + ' login:' + user.login, "login form");
        set_error(glv(`login.loginErrors.err401`  ), 'error', `: ${cutted_password(user.pass)}...`);
        shFP(true);
        return;
    }
}

export function signIn(user,  set_error, shFP, hist, dispatch) {
    dispatch(setIsLoading(true))
   return  AuthService.loginUser(user)
      .then( (response) => {
          dispatch(setIsLoading(false))
          if (response?.info?.num_status< 4){
              hist.push('/page_info#not_confirmed');
              return
          }
        if(response.code ===200 || response.code ===201){
          Cookies.set('hash_key', response.info.hash_key, {expires: 4, domain: '.bregus.eu', sameSite: 'Strict'})
            set_error(
              glv(`jointSuccesses.success_login`  ), 'success');
          return true;
        }
          conditions_codes(response.code, user,  set_error, shFP,);
          setlogos(JSON.stringify(response), "Login form");
          return false;

      })
      .catch((error) => {
        setlogos(JSON.stringify(error), "Login form");
      });

}

export function signUp(user, hist, addToast, dispatch) {
    dispatch(setIsLoading(true))
  return AuthService.registerUser(user)
      .then( (response) => {
          dispatch(setIsLoading(false))
        if(response.code === 200 || response.code ===201){
          addToast(glv(`jointSuccesses.success_register`  ),  response.code)
            hist.push('/page_info#assreg');
          return;
        }
        if(response.code >=400){
          setlogos(
              glv(`register.registerErrors.err${response.code}`  ) + ' login:' + user.login + ' email:' + user.mail, "Register form");
          addToast(glv(`register.registerErrors.err${response.code}`  ),  response.code)
          return;
        }
        setlogos(JSON.stringify(response), "Register form");
      })
      .catch((error) => {
        setlogos(JSON.stringify(error), "Register form");
        addToast('error',  '000')
      });
}
