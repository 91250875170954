import React from "react";
import appInfo from "../../../package.json";


const AppVersion = (props) => {
    return (
        <div className={'wrap_version_text'}>
            <span style={{color: '#5e5ab9'}}>{props.servInfo?.ver}{props.servInfo?.ver ? '—' : ''}{appInfo.version}</span>
            <div className={`circle ${!props.isOnl ? 'offline_' : 'online_'}`}  >
            </div>
        </div>
    );
};

export default AppVersion;
