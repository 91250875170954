import React, { useEffect, useCallback } from "react";
import {  useSelector } from "react-redux";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import sha1 from 'sha1';
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';
import  moment  from 'moment';
import {glv, Opened_Modal, set_hidden_overflow_to_body} from "../../../App";
import ReportModal from "../../modals/report_modal/Report_Modal";
import SelectLangModal from "../../modals/select_lang_modal/select_lang_modal";
import LanguageIcon from '@mui/icons-material/Language';
import {ChatIcon} from "../../../assets/svg_icons/svg_icons";
import HistoryNotificationModal from "../../modals/history_notification_modal/history_notification_modal";
import {Notifications_History_Context} from "../notification_wrapper/notifications_wrapper";

const BoxCenter = ({ children, setInternetConnDi }) => {
  const language = useSelector((state) => state.authReducer.language);
  const [showContactForm, setShowContactForm] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [open_history_toasts_modal, set_open_history_toasts_modal] = React.useState(false);
  const [opened_modal, set_opened_modal] = React.useContext(Opened_Modal);
  const [showToast, close_all_notification] = React.useContext(Notifications_History_Context);

  const offline_ = ()=>{
    let login = Cookies.get("hash_key",{domain: '.bregus.eu'});
    let errforLog = {
      timestamp: moment().unix(),
      login_hash: login ? login : "",
      point_error: "contact us",
      error_msg: "Internet connection error",
    };
    let logos = JSON.parse(localStorage.getItem("logos"));
    logos.arr.push(errforLog);
    localStorage.setItem(
        "logos",
        JSON.stringify({ key: sha1(logos.arr), arr: logos.arr })
    );
    setInternetConnDi(true);
  }




  return (
    <>
      <div className={'center_class'}  >
        <SelectLangModal open={open} close={()=>setOpen(false)} />
        <a
          href={"/"}
          className={'wrap_logo'}
          style={{

          }}
        >
          <svg
              onClick={()=>{window.location.href='/'}}
              className="logo_"
              width={222} height={224} viewBox="0 0 222 224" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_dd_1_1278)">
              <circle cx="104.5" cy="102.5" r="80.5" fill="#EFF3FB" />
              <circle cx="104.5" cy="102.5" r="80.5" stroke="url(#paint0_linear_1_1278)" strokeWidth={5} />
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M112.986 66.3447C112.986 42.822 94.189 32.3675 94.189 32.3675L90.8989 30.5233C95.2262 29.7317 99.6862 29.3181 104.243 29.3181C144.802 29.3181 177.682 62.0827 177.682 102.5C177.682 142.917 144.802 175.682 104.243 175.682C99.6862 175.682 95.2264 175.268 90.8992 174.477L94.189 172.633C94.189 172.633 112.986 162.178 112.986 138.655C112.986 113.85 83.7549 102.522 83.6978 102.5C83.7549 102.478 112.986 91.1501 112.986 66.3447ZM67.0238 165.601C86.2239 163.582 97.3161 141.815 87.1948 125.152C78.3147 110.531 55.453 110.863 36.3803 111.14L36.3787 111.14C34.6566 111.165 32.9654 111.189 31.3182 111.203C34.0765 134.4 47.7233 154.271 67.0238 165.601ZM31.3182 93.7976C34.0764 70.6002 47.7231 50.7287 67.0235 39.3995C86.2238 41.4178 97.3162 63.1848 87.1948 79.8485C78.3147 94.4688 55.453 94.137 36.3803 93.8602L36.3787 93.8601C34.6566 93.8352 32.9654 93.8106 31.3182 93.7976Z" fill="#5E5AB9" />
            <mask id="mask0_1_1278" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x={31} y={29} width={147} height={147}>
              <path fillRule="evenodd" clipRule="evenodd" d="M112.986 66.3447C112.986 42.822 94.189 32.3675 94.189 32.3675L90.8989 30.5233C95.2262 29.7317 99.6862 29.3181 104.243 29.3181C144.802 29.3181 177.682 62.0827 177.682 102.5C177.682 142.917 144.802 175.682 104.243 175.682C99.6862 175.682 95.2264 175.268 90.8992 174.477L94.189 172.633C94.189 172.633 112.986 162.178 112.986 138.655C112.986 113.85 83.7549 102.522 83.6978 102.5C83.7549 102.478 112.986 91.1501 112.986 66.3447ZM67.0238 165.601C86.2239 163.582 97.3161 141.815 87.1948 125.152C78.3147 110.531 55.453 110.863 36.3803 111.14L36.3787 111.14C34.6566 111.165 32.9654 111.189 31.3182 111.203C34.0765 134.4 47.7233 154.271 67.0238 165.601ZM31.3182 93.7976C34.0764 70.6002 47.7231 50.7287 67.0235 39.3995C86.2238 41.4178 97.3162 63.1848 87.1948 79.8485C78.3147 94.4688 55.453 94.137 36.3803 93.8602L36.3787 93.8601C34.6566 93.8352 32.9654 93.8106 31.3182 93.7976Z" fill="#FF0000" />
            </mask>
            <g mask="url(#mask0_1_1278)">
              <ellipse cx="104.757" cy="102.5" rx="39.3422" ry="39.2046" fill="white" />
            </g>
            <defs>
              <filter id="filter0_dd_1_1278" x="0.5" y="0.5" width={221} height={223} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dx={10} dy={14} />
                <feGaussianBlur stdDeviation={12} />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.118253 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_1278" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dx={-10} dy={-8} />
                <feGaussianBlur stdDeviation="5.5" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.249017 0" />
                <feBlend mode="normal" in2="effect1_dropShadow_1_1278" result="effect2_dropShadow_1_1278" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1_1278" result="shape" />
              </filter>
              <linearGradient id="paint0_linear_1_1278" x1={24} y1={22} x2={24} y2={183} gradientUnits="userSpaceOnUse">
                <stop stopColor="#D0D8E9" />
                <stop offset={1} stopColor="white" />
              </linearGradient>
            </defs>
          </svg>


        </a>
        <HistoryNotificationModal
            open={open_history_toasts_modal}
            close={()=>{
              set_open_history_toasts_modal(false)
            }}
        />
        <div className={'wrap_lang_and_notification'}>
          {
            JSON.parse(sessionStorage.getItem('notifications')).length ?
                <div
                    onClick={()=>{
                      set_open_history_toasts_modal(true)
                      close_all_notification()
                    }
                    }
                    className={'chat_icon_wrap'}>
                  <ChatIcon

                      class_name={'chat_icon'}/>
                </div> : null
          }



          <div
              className={
                'text_btn lang_select_open'
              }
              style={{zIndex: 990}}
              onClick={() => {
                if (navigator.onLine) {
                  setOpen(true)
                }
              }}
          >
            <LanguageIcon
                fontSize="small"
                style={{ color: "#57559a", marginRight: 10, fill: opened_modal ? '#e3e9f7' : '' }}
            />
            <span
                className={'button_open_feedback_modal'}
                style={{ color:  opened_modal ? '#e3e9f7' : ''  }}
                onClick={() => {}}
            >
      {language.toUpperCase()}
          </span>
          </div>
        </div>


        <div
          className={
            'text_btn contact_us'
            // classes.contUs
          }
          onClick={navigator.onLine ? ()=> {
            setShowContactForm(true)
            set_hidden_overflow_to_body('hidden')
          } :() =>  {
              offline_()
          }}
        >
          <MailOutlineIcon
            fontSize="small"
            style={{ color: "#57559a", marginRight: 10 }}
          />
          <span
            className={'button_open_feedback_modal'}
            style={{  }}
            onClick={() => {}}
          >
            {
              glv(`contact_us.title.contUs` )
            }
          </span>
        </div>
        <main className={'main_center'} >
          <div className={'paper_center'} style={{overflow: 'hidden', textAlign: 'center'}}>{children}</div>
        </main>

        <ReportModal open={showContactForm} close={()=> {
          set_hidden_overflow_to_body('auto')
          setShowContactForm(false)
        }}/>
      </div>
    </>
  );
};

export default BoxCenter;
