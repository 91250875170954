import React, { useEffect, useCallback, useState } from "react";
import InputSelect from "../../../form_components/Input_Select";
import {glv} from "../../../../App";
import Input from "../../../form_components/Input";
import FormWrapper from "./wrappers/form_wrapper";


const ReportModalForm = (props) => {
    let {form, on_change_form, errors} = props;
    
    const handle_change_input = (e)=>{
        let val = e.target.value;
        let name = e.target.name;
        on_change_form(name,val);
    }

    let custom_style= props.style ? props.style : {};
    return (
        <FormWrapper>
            <form
                className={`wrap_report_modal_form `}
                style={{...custom_style}}
            >
                <InputSelect
                    name={'theme'}
                    value={form.theme}
                    options={glv(`contact_us.contact_usForm.themes.data`, 'arr' )
                        .map((text, index)=>({id: index, text}))}
                    placeholder={glv(`contact_us.contact_usForm.placeHolders.theme`)}
                    on_change={(name, value)=>{
                        handle_change_input({
                            target:{
                                value, name
                            }
                        })
                    }}
                />

                <Input
                    wrap_style={{marginTop: 30}}
                    autocomplete={'username'}
                    value={form.name}
                    disabled={false}
                    errors={errors.name}
                    name={'name'}
                    on_change={handle_change_input}
                    ph={ glv(`contact_us.contact_usForm.placeHolders.yourName`)}
                    type={'text'}
                />
                <Input
                    wrap_style={{marginTop: 30}}
                    autocomplete={'on'}
                    value={form.contact}
                    disabled={false}
                    errors={errors.contact}
                    name={'contact'}
                    on_change={handle_change_input}
                    ph={glv(`contact_us.contact_usForm.placeHolders.yourContact`)}
                    type={'text'}
                />

                <Input
                    wrap_style={{marginTop: 30, paddingTop: 14, paddingBottom: 14}}
                    autocomplete={'on'}
                    value={form.text}
                    disabled={false}
                    errors={errors.text}
                    name={'text'}
                    on_change={handle_change_input}
                    rows={5}
                    multiline={true}
                    ph={glv(`contact_us.contact_usForm.placeHolders.yourMsgForUs`)}
                    type={'text'}
                />

            </form>
        </FormWrapper>
    );
};

export default ReportModalForm;
