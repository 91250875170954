import React, { useEffect, useCallback } from "react";
import AuthService from "../../../services/auth.service";
import Cookies from 'js-cookie';
import {glv, logout, setlogos} from "../../../App";
import BoxCenter from "../../wrap_components/global_wrappers/main_wrapper"
import LightButton from "../../custom_components/light_button";
import Backdrop from '@mui/material/Backdrop';
import CustomImageLoader from "react-custom-image-loader.";
import {Notifications_History_Context} from "../../wrap_components/notification_wrapper/notifications_wrapper";
import LightSelectButton from "../../custom_components/light_select_button";
import { useHistory } from "react-router-dom";


const SelectRedirect = (props) => {
    const [showToast, close_all_notification] = React.useContext(Notifications_History_Context);
    const [urls, setUrls] = React.useState(null);
    let history = useHistory();
    const getUrls = async () => {

        let hash = await Cookies.get("hash_key",{domain: '.bregus.eu'});
        let res = await AuthService.getAccessedLinks(
            hash,
     // '33a98709f4bc7572735c2615e4f1b97da6b11a61'
    );

        if (!res) {
          // window.location.href= '/page_info#404'
          return
        }
        if(res.code ===200 || res.code ===201){
                  if (Object.keys(res.info).length===1) {


                      if(res.info[Object.keys(res.info)[0]].url_path.constructor !== String){
                          showToast('С бека в ссылке пришла не строка', 'error')
                          setlogos({text: 'С бека в ссылке пришла не строка'}, 'select redirect page')
                          window.location.href= 'i.bregus.eu/page_info#404'
                          return
                      }

                      let domain_ = res.info[Object.keys(res.info)[0]].domain;

                      if(domain_.constructor !== String) {
                          domain_ = Object.keys(res.info)[0]+".bregus.eu";
                      }
                      let url_path_ = domain_+res.info[Object.keys(res.info)[0]].url_path;

                      if (Object.keys(res.info)[0]==='home')
                      {
                          if (Object.keys(res.info.home.all_dev).length >1) {
                              url_path_ = '/select_page'
                          }
                      }

          window.location.href='https://'+url_path_;
          return
        }
        if (!Object.keys(res.info).length) {
          window.location.href= 'i.bregus.eu/page_info#404'
            // props.history.push("/page_info#404")
          return
        }
        let copy_res_info =res.info;
        Object.keys(copy_res_info).forEach(key=>{
            let curr_domain = copy_res_info[key].domain;
            let is_valid_domain =curr_domain === undefined||curr_domain === null;
            let fallback_domain =  key+".bregus.eu";
            let is_home = key==='home';
            let length_dev_is_many =is_home ?  Object.keys(copy_res_info[key].all_dev).length> 1 : false;

                copy_res_info={...copy_res_info, [key]:{
                     ...copy_res_info[key],
                        domain:is_valid_domain ? curr_domain : fallback_domain,
                        has_col_select: length_dev_is_many
                    }
                  };

        })
            // console.log(copy_res_info)
          await setUrls(copy_res_info);
          return;
        }
        // props.history.push("/page_info#404")
        window.location.href= 'i.bregus.eu/page_info#404'
      };

    useEffect(()=>{
        if (!urls){
            getUrls();
        }

    },[urls])

    return urls?  (
        <BoxCenter logIntoReg={() => history.push("/")} >


                <h1
                    className={'select_page_title'}
                >
                    {
                        glv(`redirect_frame.frame_title`  )
                    }
                </h1>

            <div
                className={'wrap_routes'}
            >
                {
                    Object.keys(urls).map((item, index)=> {
                        if (urls[item].has_col_select) {
                            return (
                                <LightSelectButton
                                    key={item+index}
                                    text={ glv(`redirect_frame.${item}`)}
                                    cols={Object.keys(urls[item].all_dev).map((key)=>{
                                        let dev_ = urls[item].all_dev[key];
                                        return {
                                            ...dev_,
                                            id_dev: key
                                        }
                                    })}
                                    domain = {urls[item].domain}
                                    main_redirect = {
                                        'https://' + urls[item].domain + urls[item].url_path
                                    }
                                />
                            )
                        }
                        return <LightButton
                            key={item+index}
                            on_click={
                                () => {
                                    window.location.href = 'https://' + urls[item].domain + urls[item].url_path
                                }
                            }
                             cls={'route_btn'}
                             text={
                                 glv(`redirect_frame.${item}`)
                             }
                        />
                    })
                }

                <span
                    onClick={() => {
                        let login =  Cookies.get('hash_key', {domain: '.bregus.eu'});
                        if(login){
                            history.push('/page_info#authorizated')
                            return
                        }
                        history.push('/')
                    }}
                    className={'to_home_btn_select_page text_btn'}
                >
                    {
                        glv(`authorizated.authorizatedBtnsAndlinks.${'toHome'}`)
                    }
                </span>


            </div>

        </BoxCenter>
            )
        :  <Backdrop open={true}>
        <CustomImageLoader
            image={`${process.env.PUBLIC_URL}/favicon.ico`}
            isLoaded={true}
            circle={false}
            speed={2}
            animationType={"pulse"}
        />
    </Backdrop>
}

export default SelectRedirect;
