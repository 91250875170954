import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import store from './redux/configure-store';
import * as serviceWorker from './serviceWorker';
import { ConfirmProvider } from 'material-ui-confirm';
import { Provider } from "react-redux";
// import conf  from `${process.env.PUBLIC_URL}/json/lang/conf.json`;
import ErrorBoundary from "./components/wrap_components/global_exeption_wrappers/global_exeption_wrapper";
import * as allStyles from './styles/all_styles';
import NotificationWrapper from "./components/wrap_components/notification_wrapper/notifications_wrapper";
// import {ErrorBoundary} from 'react-error-boundary'


const myErrorHandler = (error, info) => {

  // Do something with the error
  // E.g. log to an error logging client here


}

ReactDOM.render(
    <NotificationWrapper >
  <ErrorBoundary >
  <Provider store={store}>
  <ConfirmProvider>

  {/* <ErrorBoundary> */}

  <App />
  {/* </ErrorBoundary> */}

</ConfirmProvider>
</Provider>
</ErrorBoundary>
    </NotificationWrapper>
    ,
  document.getElementById('root')
);

serviceWorker.unregister();
