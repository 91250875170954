import React from "react";
import {glv, Opened_Modal} from "../../../App";
import Wrap_Overlay from "../../wrap_components/overlay_wrappers/Wrap_Overlay";
import WrapModal from "../../wrap_components/modal_wrappers/Wrap_Modal";
import Title_Modal from "../../modal_components/Title_Modal";
import ActionsModalButtons from "../../modal_components/Actions_Buttons_Modal";
import ContentDescription from "./content/content_description";

const ConfirmCookiesModal = (props) => {
    const [opened_modal, set_opened_modal] = React.useContext(Opened_Modal);
    if (props.open) {
        set_opened_modal(true)
    }
    return (
        <Wrap_Overlay
            open={props.open}
            hidden_body={true}
            handle_click_overlay={()=>{
                set_opened_modal(false)
                props.close()
            }}
            class_position_children={'overlay_children_position'}
        >
            <WrapModal>
                <Title_Modal
                    title_style={{overflow:'hidden', whiteSpace:'nowrap', textOverflow: 'ellipsis'}}
                    title_text={glv(`confirm_cookie.confirm_cookieTexts.titleConf` )}
                />

                <ContentDescription text={glv(`confirm_cookie.confirm_cookieTexts.cookiePoliceContent` )}/>
                <ActionsModalButtons
                    disabled_acc_btn={false}
                    has_cancel={true}
                    has_accept={true}
                    text_accept_btn={glv(`confirm_action_buttons.ok`)}
                    text_cancel_btn={glv(`confirm_action_buttons.cancel`)}
                    style={{marginTop:30}}
                    accept_action={()=>{
                        set_opened_modal(false)
                        props.accept()
                    }}
                    cancel_action={()=>{
                        set_opened_modal(false)
                        props.close()
                    }}
                />
            </WrapModal>
        </Wrap_Overlay>
    );
};

export default ConfirmCookiesModal;
