import React, { useEffect, useCallback, useState } from "react";
import {glv, Opened_Modal, setlogos} from "../../../App";
import Wrap_Overlay from "../../wrap_components/overlay_wrappers/Wrap_Overlay";
import WrapModal from "../../wrap_components/modal_wrappers/Wrap_Modal";
import ActionsModalButtons from "../../modal_components/Actions_Buttons_Modal";
import ContainerNotifications from "./content/container_notifications";
import useWindowSize from "../../../hooks/useWindowSize";
import {RemoveIcon} from "../../../assets/svg_icons/svg_icons";

const HistoryNotificationModal = (props) => {

    const size = useWindowSize();
    const is_full_w = size.w<500;
    const [list, setList] = useState(JSON.parse(sessionStorage.getItem('notifications')));
    const [list_selected, set_list_selected] = useState([]);

    const [hover_del_block, set_hover_del_block] = useState(false);

    const [opened_modal, set_opened_modal] = React.useContext(Opened_Modal);
    if (props.open) {
        set_opened_modal(true)
    }


    useEffect(()=>{
        if (!list.length) {
            set_list_selected([])
            set_opened_modal(false)
            props.close()
        }
    }, [list, props.close])

    useEffect(()=>{
        if (props.open) {
            setList(JSON.parse(sessionStorage.getItem('notifications')).reverse())
        }
    }, [props.open])

    const delete_selected =()=> {
        let copy_list = [...list];
        list_selected.forEach((id_, index)=>{
            let index_ = copy_list.findIndex(item=>item.id===id_);
            copy_list.splice(index_, 1);
        })
        setList([...copy_list])
        set_list_selected([])
        sessionStorage.setItem('notifications', JSON.stringify([...copy_list]))
    }

    const select_all_notification =()=> {
        if (list.length===list_selected.length) {
            set_list_selected([])
        } else {
            set_list_selected(list.map(item=>item.id))
        }
    }


    const select_notification =(id)=> {
        let has_id = list_selected.findIndex(e => e === id);
        let copy_list = [...list_selected];
        if (has_id!==-1) {
            copy_list.splice(has_id, 1);
            set_list_selected([...copy_list]);
            return
        }
        set_list_selected([...list_selected, id])
    }
    const deleteToast =( id )=> {
        const listItemIndex = list.findIndex(e => e.id === id);
        let list_temp = [...list]
        if (listItemIndex!==-1){
            list_temp.splice(listItemIndex, 1);
            setList([...list_temp])
        }
            let history_notification = JSON.parse(sessionStorage.getItem('notifications'))
            const history_notification_index = history_notification.findIndex(e => e.id === id);
            if (history_notification_index!==-1){
                history_notification.splice(history_notification_index, 1);
            }
            sessionStorage.setItem('notifications', JSON.stringify([...history_notification]))
    }

    return (
        <Wrap_Overlay
            open={props.open}
            hidden_body={true}
            handle_click_overlay={()=>{
                set_list_selected([])
                set_opened_modal(false)
                props.close()
            }}
            class_position_children={'overlay_children_position'}
        >
            <WrapModal class_trigger={'history_modal'} full_w={is_full_w ? 'full_width' : ''}>

                <div className={'wrap_functions_'}>
                    <div className={'wrap_select_all'}
                         onClick={()=>{
                             if (list_selected.length) {
                                 select_all_notification()
                             }
                         }}
                    >
                            <span className={`text_btn ${ list_selected.length ===0 ? 'hover' : ''}` }> {
                                list_selected.length ===0 ?
                                    glv('notifications.select_please')
                                    :
                                    list_selected.length ===list.length ?
                                        glv('notifications.remove_selects')
                                        :
                                        glv('notifications.select_all')
                            }</span>
                    </div>

                    {
                        list_selected.length ?
                            <div className={'wrap_delete_selected'}
                                 // onMouseOver={()=>{
                                 //   set_hover_del_block(true)
                                 // }}
                                 // onMouseLeave={()=>{
                                 // set_hover_del_block(false)
                                 // }}
                                 onClick={()=>{
                                     delete_selected()
                                 }}
                            >
                            <span className={`text_btn ${hover_del_block ? 'hover': ''}` }> {
                                glv('notifications.delete_selected')
                            }

                            </span>
                                <RemoveIcon
                                    class_name={`remove_icon ${hover_del_block ? 'hover': ''}`}
                                />
                            </div> : null
                    }
                </div>


                <ContainerNotifications
                    container_style={
                    {
                        // marginTop:30,
                        maxHeight:is_full_w ? '' : size.h-250

                    }
                    }
                    full_w={is_full_w? 'full_width' : ''}
                    list_selected={list_selected}
                    list={list}
                    select_notification={(id)=>{
                        select_notification(id)
                    }}
                    delete_notification={(id)=>{
                        deleteToast(id)
                    }}
                />

                <ActionsModalButtons
                    has_cancel={true}
                    has_accept={false}
                    text_cancel_btn={glv(`confirm_action_buttons.close`)}
                    style={{margin:30, }}
                    accept_action={()=>{

                    }}
                    cancel_action={()=>{
                        set_list_selected([])
                        set_opened_modal(false)
                        props.close()
                    }}
                />

                {/*<div className={'ta_center mt_10'}>*/}
                {/*<span*/}
                {/*    onClick={() => {*/}
                {/*        set_opened_modal(false)*/}
                {/*        props.close()*/}
                {/*    }}*/}
                {/*    className={'text_btn'}*/}
                {/*>{glv(`confirm_action_buttons.cancel`)}</span>*/}
                {/*</div>*/}
            </WrapModal>
        </Wrap_Overlay>
    );
};

export default HistoryNotificationModal;

