import React, { useEffect, useCallback, useState } from "react";

const ContentPolitics = (props) => {
    let cust_style = props.style ? props.style : {}
    return (
        <div
            style={{...cust_style}}
            dangerouslySetInnerHTML={
                {__html:   props.text}
              }
            className={`politics_text `}
        />
    );
};

export default ContentPolitics;
