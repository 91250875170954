import React from "react";

const FormWrapper = (props) => {
    return (
        <div className={'report_form_struct_wrapper  options_scrollbar pos_rel'}
             style={{overflowY: 'auto'}}
        >
            {/*<CustomScroll style={{height: '100%', top:0, right: -10}}/>*/}
            {props.children}
        </div>

    );
};

export default FormWrapper;
