
import React, {useEffect,useCallback,  useState} from "react";
import Cookies from 'js-cookie';
import moment from "moment";
import sha1 from 'sha1';
import {setlogos} from '../../../App'

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    componentDidCatch(error, info) {

      // Display fallback UI
      
      this.setState({ hasError: true });
      
      let a = error;
      // console.log(a+'');
      // JSON.stringify(info.componentStack)
      // You can also log the error to an error reporting service
      setlogos(error,"global exeption" );
    }
  
    render() {
      return this.props.children;
    }
  }

  export default ErrorBoundary;