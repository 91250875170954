import * as actionTypes from '../../actions/authActions/actions.types'
import Cookies from 'js-cookie';




const dynamicalInitialState = () => {
  const initialState = {
    allUsers: [],
    isConfirm:  false,
    isConnected: false,
    language:  null,
    lang_values: null,
    lang_conf: null,
    redir_frame: false,
    isLog: false,
    is_loading: false
  };
  return initialState;
}



export default function authReducer(state = dynamicalInitialState(), action) {
  switch (action.type) {

      case actionTypes.LANG_CONF:
        return {
          ...state,
          lang_conf: action.lang_conf,
        };
    case actionTypes.IS_LOADING:
      return {
        ...state,
        is_loading: action.is_loading,
      };
        case actionTypes.REDIR_FRAME_OP:
          return {
            ...state,
            redir_frame: action.redir_frame_status,
          };
      case actionTypes.IS_CONFIRM:
        return {
          ...state,
          isConfirm: action.isConfirm,
        };


        case actionTypes.LANG_VALUES:
          return {
            ...state,
            lang_values: action.lang_values,
          };
      case actionTypes.SET_LANGUAGE:
        return {
          ...state,
          language: action.language,
        };
    default:
      return state;
  }
}
