import React from "react";
import {glv, Opened_Modal} from "../../../App";
import ContentPolitics from "./content/content_politics";
import useWindowSize from "../../../hooks/useWindowSize";
import draftToHtml from "../../../helper_functions/convert_draft_to_ html/convert_draft_to_ html";
import LightButton from "../../custom_components/light_button";
import Slide from '@mui/material/Slide';

const PoliticsModal = (props) => {
    const size = useWindowSize();
    if (props.open) {
        document.body.style.overflow = 'hidden';
    }else {
        document.body.style.overflow = '';
    }
    return   (
        <Slide direction="up" in={props.open} mountOnEnter unmountOnExit>
        <div className={'politic_modal_wrap options_scrollbar'}>
            <ContentPolitics
                style={{ textAlign: 'justify'}}
                text={
                    props.open ?  draftToHtml(JSON.parse(glv(`politics.styl_text`,  )) ) : ''
                }
            />
            <div
                className={`wrap_actions_btns_modal`}
                style={{marginTop: 10}}
            >
                <LightButton
                    on_click={() => {
                        props.close()
                    }}
                    cls={`cancel_btn`}
                    text={glv(`confirm_action_buttons.close`)}
                    style_text={{fontSize: 24, fontWeight:600, fontStyle:'normal'}}
                />
            </div>
        </div>
        </Slide>
    );
};

export default PoliticsModal;
