import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import * as actions from "../../../redux/actions/authActions/auth.actions";
// import  localization  from "../json/lang/conf.json";
import BoxCenter from "../../wrap_components/global_wrappers/main_wrapper";
import {glv, logout} from "../../../App";
import Cookies from 'js-cookie';
import PurpleButton from "../../custom_components/purple_button";
import {useHistory} from "react-router-dom";

const PageInfo = (props) => {
    let history = useHistory();
  const localization = useSelector((state) => state.authReducer.lang_values);
  const language = useSelector(state => state.authReducer.language);
  const [info, setInfo] =  useState('')
  const [title, setTitle] =  useState('')
  const dispatch = useDispatch();
  const activateUser = useCallback(
    (hist, hash) => dispatch(actions.activateUser(hist,hash)),
    [dispatch]
  );
  const checks_and_sets = React.useCallback(()=> {
      let hash = window.location.hash.slice(1, window.location.hash.length);
      let callback_set_title = (id, title, text)=>{
          let template = {id,title, text}
          setTitle(template)
      }
      let create_path = (id_, type, struct_type )=>{
          let path = type==='txt' ?
              `page_info.page_infoTexts.${id_}.${struct_type}` :
              `page_info.page_infoErrors.${id_}.${struct_type}`;
          return path;
      }
      if (hash==='not_confirmed') {
          logout(false)
      }
      let login =  Cookies.get('hash_key',{domain: '.bregus.eu'});
      if (!login&&hash==='authorizated') {
          logout()
          return
      }
      if (hash==='authorizated') {
          callback_set_title('authorizated',glv(`authorizated.authorizatedTexts.achtungTitle`))
          return
      }
      if(hash.length===1 || hash.length===0 || !hash) {
          window.location.href = '/';
          return;
      }
      let errs_path_title = create_path(hash, 'err', 'title');
      let errs_path_text= create_path(hash, 'err', 'text');
      let unk_err_path_title = create_path('unknownError', 'err', 'title');
      let unk_err_path_text = create_path('unknownError', 'err', 'text');
      let succ_title_path = create_path('successActivate', 'txt', 'title');
      let succ_text_path = create_path('successActivate', 'txt', 'text');
      let inf_texts_path_title = create_path(hash, 'txt', 'title');
      let inf_texts_path_text = create_path(hash, 'txt', 'text');
      if(hash.length < 16){

          if(glv(errs_path_title, 'str', true)!==errs_path_title)
          {
              callback_set_title(hash,glv(errs_path_title),glv(errs_path_text))
              setInfo('err');
              return
          }
          else if(glv(inf_texts_path_title, 'str', true) !==inf_texts_path_title){
              callback_set_title(hash,glv(inf_texts_path_title), glv(inf_texts_path_text))
              return
          } else {
              callback_set_title('unknownError',glv(unk_err_path_title), glv(unk_err_path_text))
              setInfo('err');
          }
          return
      }
      activateUser(history, hash).then( (response) => {

          if(response.code ===200 || response.code ===201){
              callback_set_title("successActivate", glv(succ_title_path, ), glv(succ_text_path))
              setInfo('texts');
              return
          }
          if(response.code ===401){
              callback_set_title("401",
                  glv(create_path("401", 'txt', 'title')),
                  glv(create_path("401", 'txt', 'text'))
              )
              setInfo('texts');
              return
          }
          callback_set_title('unknownError',glv(unk_err_path_title), glv(unk_err_path_text))

          setInfo('err');
      })
          .catch((error) => {
              callback_set_title('unknownError',glv(unk_err_path_title), glv(unk_err_path_text))

              setInfo('err');
          });
  }, [history])

  useEffect(()=>{
      checks_and_sets()

  }, [localization])

    useEffect(()=>{

    }, [title])

    const main_purple_button_action = ()=>{
            if (title.id==='authorizated') {
                // window.location.href= '/select_page';
              history.push('/select_page');
                return
            }
           history.push('/');
    }

    const text_btn_action = ()=>{
        if (title.id==='authorizated') {
            logout();
            return
        }
        history.goBack()
    }
    return language && localization&& title ?  (
      <BoxCenter logIntoReg={()=>history.push('/')}>



      <h1
          className={'page_info_title'}
                  style={{}}>
      {
title.title}
      </h1>

      <span
          className={'page_info_description'}
          style={{
          display:  title==='authorizated' ? 'none' : '',

      }}>
        {title.text}
      </span>

          <PurpleButton
              on_click={ main_purple_button_action}
              cls_outer={`main_purple_button`}
              // cls_inner={}
              disabled={props.disabled_acc_btn}
              text={

                      title.id==='authorizated' ?
                          glv(`authorizated.authorizatedBtnsAndlinks.${'toHome'}`)
                          :
                          glv(`page_info.page_infoBtnsAndlinks.${'redirectOnMain'}`)
              }
          />
          {/*<button*/}
          {/*    onClick={main_purple_button_action}*/}
          {/*    className={'purple_button main_purple_button'}>*/}
          {/*    <span className={'purple_button_text'}>*/}

          {/*    </span>*/}
          {/*</button>*/}

          <span
              className={'text_btn page_info_text_btn'}
             onClick={text_btn_action}
          >
                          {
                              title.id==='authorizated'?
                                  glv(`authorizated.authorizatedBtnsAndlinks.${'logaut'}`)
                                  :
                                  glv(`page_info.page_infoBtnsAndlinks.${'goBack'}`)
                          }
          </span>

      </BoxCenter>

  ) : null ;
};

export default PageInfo;
