import React from "react";

const LightButton = (props) => {
    let cust_style= props.style ? props.style : {};
    let cust_style_text= props.style_text ? props.style_text : {};
    let cls = props.cls ? props.cls : '';

    return (
        <button
            type={'button'}
            onClick={props.disabled ? ()=>{}:()=>props.on_click()}
            className={`light_button ${cls}`}
            style={cust_style}
        >

                    <span
                        style={cust_style_text}
                        className={'light_button_text'}>
                      {props.text}
                     </span>

        </button>
    );
};

export default LightButton;
