import React, { useEffect, useCallback, useState } from "react";
import InputSelect from "../../../form_components/Input_Select";
import {glv} from "../../../../App";
import Input from "../../../form_components/Input";



const ReqRenewPasswordForm = (props) => {
    let {form, on_change_form, errors} = props;

    const handle_change_input = (e)=>{
        let val = e.target.value;
        let name = e.target.name;
        on_change_form(name,val);
    }

    let custom_style= props.style ? props.style : {};
    return (
            <form
                className={`wrap_report_modal_form `}
                style={{...custom_style}}
            >

                <Input
                    wrap_style={{marginTop: 30}}
                    autocomplete={'email'}
                    value={form.email}
                    disabled={false}
                    errors={errors.email}
                    name={'email'}
                    on_change={handle_change_input}
                    ph={glv(`jointFormData.placeHolders.${'email'}`)}
                    type={'text'}
                />
            </form>
    );
};

export default ReqRenewPasswordForm;
