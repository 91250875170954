
import sha1 from 'sha1';

import {set_env_urls, env_urls, setlogos, test_domen} from "../App";
const superagent = require('superagent');

let walid_apikey = false;
let counter_apikey = 0;



function CheckWrap(

    url,
    body,
    type,
    point,
    returned_type,
    prevFunc,
    postFunc,
    sha1,
    moment,
    Cookies,
) {

  let newApiKey = url.apiKey;

  prevFunc ?  prevFunc() : console.log();

  let recursive_update = ()=> {
    if (!walid_apikey) {
      return CheckWrap(
          {...url,apiKey: newApiKey },
          body,
          type,
          point,
          returned_type,
          prevFunc,
          postFunc,
          sha1,
          moment,
          Cookies,
      )
    }
    return setTimeout(()=>recursive_update(), 4)
  }

  let update_confs =  () => {

    let curr_url = `${test_domen}/json/conf.json`;

    superagent.get(curr_url)
        .then((res) => {

          let body = res.body;
          // обновляется ключ
          newApiKey = body.keys.apiKey;

          let new_urls = {
            apiKey: body.keys.apiKey,
            url: body.url.api,
          };
          set_env_urls({...new_urls})
        //  setUrls({...new_urls});
          walid_apikey =  false;
          return true
        })
        .catch((error) => {

          setlogos(
              JSON.stringify({error: error.name, info: error.message}),
              "error when get new api key",
              sha1,
              moment,
              Cookies
          );
          walid_apikey =  false;
          return  false
        });

  }


  let push_req =  () => {

    // axios(
    //     {
    //       url: `${url.url}/${newApiKey}${url.path}${url.params}`,
    //       method: type,
    //       headers: {
    //         "Content-Type": "application/json;charset=utf-8",
    //       },
    //       data: type === "GET" ? null : JSON.stringify(body),
    //     }
    // )
    return superagent(type, `${url.url}/${newApiKey}${url.path}${url.params}`)
        .send(type === "GET" ? null : JSON.stringify(body))
        .set('Accept', 'application/json')
        .then( (res)=>{
      return res
    }).catch((error) => {
      setlogos(
          JSON.stringify({error: error.name, info: error.message}),
          point,
          sha1,
          moment,
          Cookies
      );
      return {status: 'error'}
    });

  }


  // обертка трай для отлова ошибок
  let future_res =null;
  try {
    if (!walid_apikey) {
      return  push_req().then((res_req)=> {
        if (res_req.status === 200) {
          postFunc ? postFunc() : console.log();
          counter_apikey = 0;

          let future_res = res_req.body;
          if (!future_res) {
            future_res= JSON.parse(res_req.text);
          }
          if (returned_type==='blob'){
            future_res =  res_req.blob();
          }

          return future_res;
        }

        if (res_req.status !== 200) {
          walid_apikey = true;

          if (counter_apikey + 1 > 10) {
            window.location.href = "https://i.bregus.eu/page_info#404";
            return;
          }
          counter_apikey = counter_apikey + 1;
          let  resres = update_confs();
          return recursive_update();
        }

      });

    }
  } catch (error) {
    // вызов функции записи ошибок
    setlogos(
        JSON.stringify({error: error.name, info: error.message}),
        point,
        sha1,
        moment,
        Cookies
    );
  }
}



export default class AuthService {

  static loginUser = async (user) => {
    let params = `?login=${user.login}&pass=${sha1(user.login+user.pass)}`;

    let req_url = {
      ...env_urls,
      path: '/user/login',
      params: params
    };
    let req_body = null;
    let req_type = "GET";
    let req_point = " Page: Login/ Type: request error/ URL: /user/login function: loginUser";

    return await CheckWrap(
        req_url,
        req_body,
        req_type,
        req_point
    );
  };

  static logout = async (hash) => {
    let params = `?hash_key=${hash}`;

    let req_url = {
      ...env_urls,
      path: '/user/logaut',
      params: params
    };
    let req_body = null;
    let req_type = "GET";
    let req_point = " Page: Any/ Type: request error/ URL: /user/logaut function: logout";

    return await CheckWrap(
        req_url,
        req_body,
        req_type,
        req_point
    );
  };

  static getAccessedLinks = async (hash) => {

    let params = `?hash_key=${hash}`;

    let req_url = {
      ...env_urls,
      path: '/load/goshan',
      params: params
    };
    let req_body = null;
    let req_type = "GET";
    let req_point = " Page: Any/ Type: request error/ URL: /load/goshan function: getAccessedLinks";

    return await CheckWrap(
        req_url,
        req_body,
        req_type,
        req_point
    );
  };


  static registerUser = async (user) => {

    let params = ``;

    let req_url = {
      ...env_urls,
      path: '/user/reg',
      params: params
    };
    let req_body = user;
    let req_type = "POST";
    let req_point = " Page: Register/ Type: request error/ URL: /user/reg function: registerUser";

    return await CheckWrap(
        req_url,
        req_body,
        req_type,
        req_point
    );
  };

  static activateUser = async (hash) => {

    let params = `?mail_key=${hash}`;

    let req_url = {
      ...env_urls,
      path: '/user/activate',
      params: params
    };
    let req_body = null;
    let req_type = "GET";
    let req_point = " Page: Register/ Type: request error/ URL: /user/activate function: activateUser";

    return await CheckWrap(
        req_url,
        req_body,
        req_type,
        req_point
    );
  };

  static getInfo =  async (width, height, hash) => {
    let hashKey =  hash? hash : '';

    let params = `?set=${width}:${height}&hash=${hashKey}`;

    let req_url = {
      ...env_urls,
      path: '/user/im_alive',
      params: params
    };
    let req_body = null;
    let req_type = "GET";
    let req_point = " Page: any/ Type: request error/ URL: /user/activate function: getInfo";

    return await CheckWrap(
        req_url,
        req_body,
        req_type,
        req_point
    );
  };

  static resetPass =async (hash) => {
    let params = `?hash=${sha1(hash)}`;

    let req_url = {
      ...env_urls,
      path: '/user/reset_pass',
      params: params
    };
    let req_body = null;
    let req_type = "GET";
    let req_point = " Page: any/ Type: request error/ URL: /user/reset_pass function: resetPass";

    return await CheckWrap(
        req_url,
        req_body,
        req_type,
        req_point
    );
  };


  static contactForm =async (form) => {

    let params = ``;

    let req_url = {
      ...env_urls,
      path: '/set/contact_form',
      params: params
    };
    let req_body = form;
    let req_type = "POST";
    let req_point = " Page: any/ Type: request error/ URL: /user/reset_pass function: contactForm";

    return await CheckWrap(
        req_url,
        req_body,
        req_type,
        req_point
    );
  };

  static logosErr = async(form) => {
    let params = ``;

    let req_url = {
      ...env_urls,
      path: '/set/front_catch_log',
      params: params
    };
    let req_body = form;
    let req_type = "POST";
    let req_point = " Page: any/ Type: request error/ URL: /set/front_catch_log function: logosErr";

    return await CheckWrap(
        req_url,
        req_body,
        req_type,
        req_point
    );
  };


}
