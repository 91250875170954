import React, {useEffect, useCallback, useState} from "react";
import * as actions from "../../../../redux/actions/authActions/auth.actions";
import {useDispatch, useSelector} from "react-redux";
import {glv, Opened_Modal} from "../../../../App";


const ContentSelectLangs = (props) => {
    const language = useSelector((state) => state.authReducer.language);
    const langConf = useSelector((state) => state.authReducer.lang_conf);
    const dispatch = useDispatch();

    const changeLanguage = useCallback(
        (lang) => dispatch(actions.setLanguage(lang)),
        [dispatch]
    );
    return (
        <div
            style={
                {zIndex: 5165}
            }
        >
            {Object.keys(langConf.language_arr).map((item, index) => (
                <button
                    key={index + 'lang' + item}
                    onClick={() => {
                        changeLanguage(item);
                        props.close();
                    }}
                    className={`light_button lang_btn lang_to_select_btn ${language === item ? 'selected_lang' : ''}`}
                    style={{position: 'relative'}}
                >

                    <span className={'light_button_text'}>
                               {langConf.language_arr[item].name}
                     </span>

                </button>
            ))}
            <div className={'ta_center mt_30'}>
                <span
                    onClick={() => props.close()}
                    className={'text_btn'}
                >{glv(`confirm_action_buttons.cancel`)}</span>
            </div>

        </div>
    );
};

export default ContentSelectLangs;
