import React from "react";
import {warn} from "../../../assets/images";

const CrashIcon = (props)=> {

    return (
        <div className={'wrap_crash_icon'}>
            <img src={warn} alt="" width="100px"/>
        </div>
    )
}

export default CrashIcon;