import React, {useEffect, useCallback, useState} from "react";
import SignInForm from "./form/SignInForm";
import BoxCenter from "../../wrap_components/global_wrappers/main_wrapper";
import {useDispatch, useSelector} from "react-redux";
import Grow from '@mui/material/Grow';
import OfflineDialog from '../../modals/offline_modal/offlineModal';
import {glv, setlogos} from "../../../App";
import ContactModal from "../../modals/contact_modal/contact_modal";
import {signIn} from "../../../redux/actions/authActions/auth.actions";
import {Notifications_History_Context} from "../../wrap_components/notification_wrapper/notifications_wrapper";
import {useHistory} from "react-router-dom";

const appCrash = () => {
    throw new Error(' crashed!');
}

const SignIn = (props) => {
    const dispatch = useDispatch();
    const [showToast, close_all_notification] = React.useContext(Notifications_History_Context);
    let history = useHistory();
    const [showContactForm, setShowContactForm] = React.useState(false);
    const language = useSelector((state) => state.authReducer.language);
    const isConfirm = useSelector((state) => state.authReducer.isConfirm);

    const [errorShow, setErrorShow] = useState("");
    const [cased, setCased] = useState("");
    const [showFP, setShowFP] = useState(false);
    const [internetConnDi, setInternetConnDi] = useState(false);
    const localization = useSelector((state) => state.authReducer.lang_values);

    const set_error = (error_text, cased, additional_toast_text) => {
        let addit_toast_text = additional_toast_text ? additional_toast_text : '';
        showToast(error_text+addit_toast_text, 'info')
        setErrorShow(error_text);
        setCased(cased);
    };
    const no_internet =()=>{
        setlogos("Internet connection error", "try reset pass");
        setInternetConnDi(true);
        return;
    }

    const login = (user) => {
        if (!navigator.onLine) {
            no_internet()
            return;
        }
        signIn(user,  set_error, setShowFP, history, dispatch).then((result)=>{
            if (result){
                history.push('/select_page')
            }
        })
    };

    return language && localization ? (
        <BoxCenter logIntoReg={() => history.push("/")} setInternetConnDi={setInternetConnDi}>
            {!isConfirm ? (
                <h1
                    className={'not_confirmed_text'}
                >
                    {
                        glv(`confirm_cookie.confirm_cookieErrors.${'notConfirmed'}`)

                    }
                </h1>
            ) : null}

            {
                internetConnDi ?
                    < OfflineDialog
                        open={internetConnDi}
                        close={()=>setInternetConnDi(!internetConnDi)}
                    />  : null
            }

                <Grow in={errorShow} unmountOnExit={true}>
                    <h1
                        className={`status_text ${  cased === "success" ? 'success' : 'error'}`}
                    >
                        {errorShow}
                    </h1>
                </Grow>

            <SignInForm
                handleSubmit={(data) => login(data)}
                fP={setShowContactForm}
                shFP={showFP}
                errSho={setErrorShow}
            />

            <ContactModal
                open={showContactForm}
                close={()=>setShowContactForm(false)}
                no_internet={no_internet}
            />

        </BoxCenter>
    ) : null;
};

export default SignIn;
