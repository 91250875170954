import React, {useEffect, useCallback, useState} from "react";

import Grow from '@mui/material/Grow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {useSelector} from "react-redux";
import {glv} from "../../../../App";
import Input from "../../../form_components/Input";
import PurpleButton from "../../../custom_components/purple_button";
import {Notifications_History_Context} from "../../../wrap_components/notification_wrapper/notifications_wrapper";


const SignInForm = ({ handleSubmit, fP, shFP, errSho}) => {
    const isConfirm = useSelector((state) => state.authReducer.isConfirm);

    const [errors, set_errors] = useState({
        login: [],
        password: [],
    });
    const [login_, set_login] = useState('');
    const [password_, set_password] = useState('');
    const [isShowPass, setIsShowPass] = useState(false);

    useEffect(() => {
        if (login_ || password_) {
            errSho('');
        }
    }, [login_, password_]);


    const validate_input = React.useCallback((name, value) => {
        let new_arr_errs = [];
        const callback_set = () => set_errors({...errors, [name]: new_arr_errs});

        switch (name) {
            case 'login': {
                if (value.length < 4) {
                    new_arr_errs.push('min_length_6')
                }
                if (value.length > 40) {
                    new_arr_errs.push('max_length')
                }
                // let re = /^[a-zA-Z0-9!?\^&\]*\:()\;[{}(|+=._-]*$/
                //
                // if (!re.test(value)) {
                //     new_arr_errs.push('spec_sym_allow')
                // }
                return callback_set()
            }
            case 'password': {

                if (value.length < 8) {
                    new_arr_errs.push('min_length_8')
                }

                if (value.length >= 40) {
                    new_arr_errs.push('max_length')
                }
                return callback_set()
            }
        }
    }, [login_, password_,])

    const check_has_errs = () => {
        let {login, password,} = errors;

        if (login.length || password.length) {
            return false
        }

        return true
    }

    const handleSubmitCallback = useCallback(
        (evt) => {
            // evt.preventDefault();
            let login = errors.login;
            let password = errors.password;
            let changed = false;

            if (!login_) {
                login = [...login, 'reqValid'];
                changed = true;
            }
            if (!password_) {
                password = [...password, 'reqValid'];
                changed = true;
            }
            if (changed) {
                set_errors({...errors, login, password})
                return
            }

            handleSubmit({login: login_, pass: password_});
        },
        [login_, password_, handleSubmit]
    );

    return (
        <form
            className={'w100'}
            onSubmit={(e) => handleSubmitCallback(e)}
            autoComplete={'on'}
        >
            <Input
                autocomplete={'username'}
                no_mt={true}
                value={login_}
                disabled={!isConfirm ? true : false}
                errors={errors.login}
                name={'login'}
                on_change={(e) => {
                    let val = e.target.value;
                    let name = e.target.name;
                    validate_input(name, val);
                    set_login(val);
                }}
                ph={glv(`jointFormData.placeHolders.log`)}
                type={'text'}
            />


            <Input
                autocomplete={'current-password'}
                disabled={!isConfirm ? true : false}
                errors={errors.password}
                has_fake={true}
                value={password_}
                name={'password'}
                on_change={(e) => {
                    let val = e.target.value;
                    let name = e.target.name;
                    validate_input(name, val);
                    set_password(val)
                }}
                has_icon={true}
                icon={isShowPass ? (
                    <VisibilityOffIcon
                        color="#2F2A61"
                        style={{cursor: "pointer", fill: "#2F2A61"}}
                    />
                ) : (
                    <VisibilityIcon
                        color="#2F2A61"
                        style={{cursor: "pointer", fill: "#2F2A61"}}
                    />
                )
                }
                icon_press={() => {
                    setIsShowPass(!isShowPass)
                }}
                ph={glv(`jointFormData.placeHolders.pass`)}
                type={isShowPass ? 'text' : 'password'}
            />
            <div
                className={'wrap_btns_sign_in'}

            >

                {
                    shFP ?
                        <Grow in={shFP}
                              style={{transformOrigin: '0 0 0'}}
                              {...({timeout: 1000})}
                        >
                            <div
                                className={'wrap_forgon_pass_bt'}
                            >
              <span
                  onClick={() => {
                      fP(true);
                  }}
                  className={'text_btn'}>
                              {
                                  glv(`login.loginBtnsAndlinks.forgotPass`)
                              }
              </span>
                            </div>
                        </Grow> : null
                }


                <PurpleButton
                    on_click={() => handleSubmitCallback()}
                    cls_outer={`sign_in_btn ${!isConfirm || !check_has_errs() ? 'disabled' : ''}`}
                    // cls_inner={}
                    disabled={!isConfirm || !check_has_errs()}
                    text={glv(`login.loginBtnsAndlinks.login`)}
                />


            </div>
        </form>
    );
};

export default SignInForm;
