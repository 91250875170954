import React, { useEffect, useCallback, useState } from "react";
import Notification from "../../../custom_components/Toast/notification";
import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group';
import {RemoveIcon} from "../../../../assets/svg_icons/svg_icons";
import {glv} from "../../../../App";

const ContainerNotifications = (props) => {
    let class_full_w = props.full_w ? props.full_w : '';
    return (
        <div
            style={props.container_style}
            className={`container_notifications_modal options_scrollbar ${class_full_w}`}>


            <ul>
                {
                    props.list.map((item, index)=>{
                        let is_selected = props.list_selected.findIndex(notif=>notif===item.id)!==-1
                        return (

                                <Notification
                                    icon={
                                        <div onClick={()=>{
                                            props.delete_notification(item.id)
                                        }
                                        }>
                                        <RemoveIcon
                                            class_name={'remove_icon'}
                                        />
                                    </div>}
                                    fake_top_margin={20}
                                    index={index}
                                    class_trigger={`history_modal ${is_selected? 'selected_':''}`}
                                    text={item.text}
                                    time={item.time}
                                    code={item.code}
                                    not_close = {true}
                                    on_close={()=>{
                                        props.delete_notification(item.id)
                                    }}
                                    on_hide={()=>{
                                        props.select_notification(item.id)
                                    }}
                                />


                        )
                    })
                }
            </ul>
        </div>
    );
};

export default ContainerNotifications;
