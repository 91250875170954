import React, { useEffect, useCallback, useState } from "react";
import InputSelect from "../../form_components/Input_Select";
import {glv, Opened_Modal} from "../../../App";
import Wrap_Overlay from "../../wrap_components/overlay_wrappers/Wrap_Overlay";
import WrapModal from "../../wrap_components/modal_wrappers/Wrap_Modal";
import Title_Modal from "../../modal_components/Title_Modal";
import ActionsModalButtons from "../../modal_components/Actions_Buttons_Modal";
import ReportModalForm from "./form/Report_Modal_Form";
import { useSelector } from "react-redux";
import AuthService from "../../../services/auth.service";
import useWindowSize from "../../../hooks/useWindowSize";
import {Notifications_History_Context} from "../../wrap_components/notification_wrapper/notifications_wrapper";


let template_report_form = {
    theme: '',
    name: '',
    contact: '',
    text: '',
}

const ReportModal = (props) => {
    const [showToast, close_all_notification] = React.useContext(Notifications_History_Context);
    const [opened_modal, set_opened_modal] = React.useContext(Opened_Modal);
    const size = useWindowSize();
    if (props.open) {
        set_opened_modal(true)
    }
    const language = useSelector((state) => state.authReducer.language);

    const [form, set_form] = React.useState(
        template_report_form
    );

    const [errors, set_errors] = React.useState(
        {
            theme: [],
            name: [],
            contact: [],
            text: [],
        }
    );
    const validate_ = React.useCallback((name, value)=>{
        let new_arr_errs = [];
        const callback_set = ()=>set_errors({...errors, [name]: new_arr_errs});

        switch (name) {
            case 'name': {
                if (value.length < 6) {
                    new_arr_errs.push('min_length_6')
                }
                if ( value.length >= 40) {
                    new_arr_errs.push('max_length')
                }

                    `.,!?'"`.split('').forEach(symb =>{
                    if ( value.includes(symb)) {
                        new_arr_errs.push('spec_sym_allow')
                    }
                })

                return callback_set()
            }
            case 'contact': {
                if (value.length < 6) {
                    new_arr_errs.push('min_length_6')
                }
                if ( value.length >= 40) {
                    new_arr_errs.push('max_length')
                }
                return callback_set()
            }
        }
    }, [form])



    const handle_change_form =React.useCallback(
        (name, value)=>{
            validate_(name, value)
            set_form({...form, [name]:value})
        },
        [form]
    )

    const check_allow_submit =React.useCallback(
        ()=>{
            if(isNaN(form.theme)||!form.name||!form.contact||!form.text) {
                return false;
            }
            let has_some_error =
                errors.theme.length||
                errors.name.length||
                errors.contact.length||
                errors.text.length;
            if (has_some_error) {
                return false
            }
            return true;
        },
        [form, errors]
    )

    const submit_report = useCallback(async () => {
        props.close();
        set_opened_modal(false)
        AuthService.contactForm({
            ...form,
            theme: glv(`contact_us.contact_usForm.themes.data`, 'arr' )[form.theme],
            lang: language,
        });
        showToast(glv(`contact_us.alert.info` ), 300);
        set_form(template_report_form)
    }, [form, language, props.close]);

    return (
        <Wrap_Overlay
            open={props.open}
            hidden_body={true}
            handle_click_overlay={()=>{
                props.close()
                set_opened_modal(false)
            }}
            class_position_children={'overlay_children_position'}
        >
            <WrapModal>
                <Title_Modal
                    title_text={glv(`contact_us.title.contUs` )}
                />
                <ReportModalForm
                    style={{maxHeight: size.h-210}}
                    form={form}
                    on_change_form={(name, value)=>handle_change_form(name, value)}
                    errors={errors}
                />
                <ActionsModalButtons
                    disabled_acc_btn={
                        !check_allow_submit()
                    }
                    has_cancel={false}
                    has_accept={true}
                    text_accept_btn={glv(`contact_us.contact_usBtnsAndlinks.submit`)}
                    style={{marginTop:30}}
                    accept_action={()=>{
                        submit_report()
                    }}
                    cancel_action={()=>{
                        props.close()
                        set_opened_modal(false)
                    }}
                />
            </WrapModal>
        </Wrap_Overlay>
    );
};

export default ReportModal;
