import React, {useEffect, useCallback, useState} from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';

import {useSelector} from "react-redux";
import {env_urls, glv} from "../../../../App";
import CloseIcon from '@mui/icons-material/Close';
import Input from "../../../form_components/Input";
import {validateEmail} from "../../../../helper_functions/helpers";
import PurpleButton from "../../../custom_components/purple_button";
import Checkbox from "../../../custom_components/checkbox";
import PoliticsModal from "../../../modals/politics_modal/politics_modal";
import {Notifications_History_Context} from "../../../wrap_components/notification_wrapper/notifications_wrapper";
import {useHistory} from "react-router-dom";


const RefCode = (props) => {
    return (
        <div className={'ref_code_wrap w100 fl_ '}>
            <span className={''}>{glv('register.registerForm.ref_code')}:</span>
            <span className={'mg_l_5 fw_bold'}>{props.code}</span>
        </div>
    )
}


const SignUpForm = ({ handleSubmit, props, errSh}) => {
    const [showToast, close_all_notification] = React.useContext(Notifications_History_Context);
    let history = useHistory();
    const [isShowPass, setIsShowPass] = useState(false);

    const [errors, set_errors] = useState({
        login: [],
        password: [],
        confirm_pass: [],
        email: [],
        referal: []
    });

    const [login_, set_login] = useState('');
    const [password_, set_password] = useState('');
    const [confirm_pass_, set_confirm_pass] = useState('');
    const [email_, set_email] = useState('');
    const [confirm_politics, set_confirm_politics] = useState(true);
    const [open_politics_modal, set_open_politics_modal] = useState(false);
    const [referal_input, set_referal_input] = useState({
        value: '',
        disabled: false
    });

    const isConfirm = useSelector((state) => state.authReducer.isConfirm);
    const [isShowConfirmPass, setIsShowConfirmPass] = useState(false);
    const [is_show_referal_input, set_is_show_referal_input] = useState(false);


    useEffect(() => {
        if (login_ || email_ || password_) {
            errSh('');
        }
    }, [login_, email_, password_]);

    const check_has_errs = () => {
        let {login, email, password, confirm_pass, referal} = errors;

        if (!login_ || !email_ || !password_ || !confirm_pass_||!confirm_politics) {
            return false
        }

        if (login.length || email.length || password.length || confirm_pass.length) {
            return false
        }
        if (is_show_referal_input && referal.length) {
            return false
        }
        return true
    }


    const validate_input = React.useCallback((name, value) => {
        let new_arr_errs = [];
        const callback_set = () => set_errors({...errors, [name]: new_arr_errs});

        switch (name) {
            case 'login': {
                if (value.length < 6) {
                    new_arr_errs.push('min_length_6')
                }
                if (value.length >= 40) {
                    new_arr_errs.push('max_length')
                }
                let re = /^[a-zA-Z0-9!?\^&\]*\:()\;[{}(|+=._-]*$/

                if (!re.test(value)) {
                    new_arr_errs.push('spec_sym_allow')
                }
                return callback_set()
            }
            case 'password': {

                if (value.length < 8) {
                    new_arr_errs.push('min_length_8')
                }

                if (value.length >= 40) {
                    new_arr_errs.push('max_length')
                }
                return callback_set()
            }
            case 'email': {

                if (!validateEmail(value)) {
                    new_arr_errs.push('emailValid')
                }
                return callback_set()
            }
            case 'referal': {
                if (!/^[a-zA-Z0-9]+$/g.test(value)) {
                    new_arr_errs.push('lat_num')
                }
                if (value.length < 4 || value.length > 10) {
                    new_arr_errs.push('length_valid_10')
                }
                return callback_set()
            }
            case 'confirm_pass': {
                if (value.length < 8) {
                    new_arr_errs.push('min_length_8')
                }

                if (value.length >= 40) {
                    new_arr_errs.push('max_length')
                }

                if (value !== password_) {
                    new_arr_errs.push('pass_mismatch')
                }

                return callback_set()
            }
        }
    }, [login_, email_, password_, confirm_pass_, referal_input])

    useEffect(() => {
        let hash_ = window.location.hash.replace(/^#/, '');
        let test_lan_num = /^[a-zA-Z0-9]+$/g.test(hash_);
        if (hash_ &&
            test_lan_num && hash_.length >= 4 && hash_.length <= 10) {
            set_is_show_referal_input(true)
            set_referal_input({value: hash_, disabled: true})
        }
    }, []);

    const handleSubmitCallback = useCallback(
        (evt) => {
            // evt.preventDefault();

            if (confirm_pass_ !== password_) {

                showToast(glv('register.registerErrors.err500'), 500);
                return;
            }
            setIsShowConfirmPass(false);
            let form_ = {login: login_, pass: password_, mail: email_,};
            if (referal_input?.value && is_show_referal_input) {
                form_.sucode = referal_input.value;
            }
            handleSubmit(form_);
        },
        [confirm_pass_, email_, password_, login_, handleSubmit, isShowConfirmPass, referal_input, is_show_referal_input]
    );

    return (
        <form className={'w100'}
              // onSubmit={(e) => handleSubmitCallback(e)}
        >
            <PoliticsModal
                open={open_politics_modal}
                close={()=>{
                 set_open_politics_modal(false)
                }}
            />
            <Input
                value={login_}
                disabled={!isConfirm ? true : false}
                errors={errors.login}
                name={'login'}
                on_change={(e) => {
                    let val = e.target.value;
                    let name = e.target.name;
                    validate_input(name, val);
                    set_login(val);
                }}
                ph={glv(`jointFormData.placeHolders.log`)}
                type={'text'}
            />

            <Input
                disabled={!isConfirm ? true : false}
                errors={errors.email}
                name={'email'}
                value={email_}
                on_change={(e) => {
                    let val = e.target.value;
                    let name = e.target.name;
                    validate_input(name, val);
                    set_email(val)
                }}
                ph={glv(`jointFormData.placeHolders.email`)}
                type={'text'}
            />

            <Input
                disabled={!isConfirm ? true : false}
                autocomplete={'new-password'}
                errors={errors.password}
                value={password_}
                name={'password'}
                on_change={(e) => {
                    let val = e.target.value;
                    let name = e.target.name;
                    validate_input(name, val);
                    set_password(val)
                }}
                has_icon={true}
                icon={isShowPass ? (
                    <VisibilityOffIcon
                        color="#2F2A61"
                        style={{cursor: "pointer", fill: "#2F2A61"}}
                    />
                ) : (
                    <VisibilityIcon
                        color="#2F2A61"
                        style={{cursor: "pointer", fill: "#2F2A61"}}
                    />
                )
                }
                icon_press={() => {
                    setIsShowPass(!isShowPass)
                }}
                ph={glv(`jointFormData.placeHolders.pass`)}
                type={isShowPass ? 'text' : 'password'}
            />

            <Slide direction="up" in={password_.length} unmountOnExit>
                <div>
                    <Input
                        errors={errors.confirm_pass}
                        value={confirm_pass_}
                        name={'confirm_pass'}
                        on_change={(e) => {
                            let val = e.target.value;
                            let name = e.target.name;
                            validate_input(name, val);
                            set_confirm_pass(val)
                        }}
                        has_icon={true}
                        icon={isShowConfirmPass ? (
                            <VisibilityOffIcon
                                color="#2F2A61"
                                style={{cursor: "pointer", fill: "#2F2A61"}}
                            />
                        ) : (
                            <VisibilityIcon
                                color="#2F2A61"
                                style={{cursor: "pointer", fill: "#2F2A61"}}
                            />
                        )
                        }
                        icon_press={() => {
                            setIsShowConfirmPass(!isShowConfirmPass)
                        }}
                        ph={''}
                        type={isShowConfirmPass ? 'text' : 'password'}
                    />
                </div>
            </Slide>


            <Slide direction="up" in={is_show_referal_input && !referal_input.disabled} unmountOnExit>
                <div>
                    <Input
                        // classes={'ref_cod_bg'}
                        disabled={referal_input.disabled}
                        style={{border: referal_input.disabled ? '' : ''}}
                        errors={errors.referal}
                        value={referal_input.value}
                        has_icon={true}
                        icon={isShowPass ? (
                            <CloseIcon
                                color="#2F2A61"
                                style={{cursor: "pointer", fill: "#2F2A61"}}
                            />
                        ) : (
                            <CloseIcon
                                color="#2F2A61"
                                style={{cursor: "pointer", fill: "#2F2A61"}}
                            />
                        )
                        }
                        icon_press={() => {
                            set_is_show_referal_input(!is_show_referal_input)
                        }}
                        on_change={(e) => {
                            let val = e.target.value;
                            let name = e.target.name;
                            validate_input(name, val);
                            set_referal_input({...referal_input, value: val})
                        }}
                        name={"referal"}
                        ph={glv(`jointFormData.placeHolders.referal`)}
                        type={'text'}
                    />
                </div>
            </Slide>
            {
                !is_show_referal_input ?
                    <Grow in={true}
                          style={{transformOrigin: '0 0 0'}}
                          {...({timeout: 1000})}
                    >
                        <div className={'wrap_ref_code'}>
                    <span
                        style={{
                            color: !isConfirm ? '#999bba' : '',
                            cursor: !isConfirm ? 'default' : ''
                        }}
                        className={'text_btn'}
                        onClick={() => {
                            if (!isConfirm) {
                                return
                            }
                            set_is_show_referal_input(true)
                        }}
                    >
                                          {
                                              glv(`register.registerBtnsAndlinks.enable_referal`)
                                          }
                    </span>
                        </div>
                    </Grow>
                    :
                    is_show_referal_input && referal_input.disabled ?
                        (
                            <RefCode code={referal_input.value}/>
                        )
                        :
                        null
            }



            {/*убираем политику до времен когда дизайнеры отрисуют текст*/}
            <div style={{marginTop: 30}}></div>
            <div className={'wrap_allow_politics'} style={{display: 'none'}}>
                    <span
                        style={{
                            color: !isConfirm ? '#999bba' : '',
                            cursor: !isConfirm ? 'default' : ''
                        }}
                        className={'text_btn all_pol_text'}
                        onClick={() => {
                            if (!isConfirm) {
                                return
                            }
                            set_open_politics_modal(true)
                        }}
                    >
                                          {
                                              glv('register.registerForm.allow_politics')
                                          }
                    </span>
                    <Checkbox
                        checked={confirm_politics}
                        on_change={()=>{
                           set_confirm_politics(!confirm_politics)
                    }}
                    />
            </div>

            <div
                className={'wrap_sign_up_btn'}
            >

                <PurpleButton
                    on_click={() => handleSubmitCallback()}
                    cls_outer={`sign_up_btn ${!isConfirm || !check_has_errs() ? 'disabled' : ''}`}
                    // cls_inner={}
                    disabled={!isConfirm || !check_has_errs()}
                    text={glv(`register.registerBtnsAndlinks.register`)}
                />

                <p
                    className={'text_btn back_to_sign_in'}
                    onClick={() => history.push("/")}
                >
                    {
                        glv(`register.registerBtnsAndlinks.backToLogin`)
                    }
                </p>
            </div>
        </form>
    );
};

export default SignUpForm;
