import React, { useEffect, useCallback, useState } from "react";
import {Using_Mobile_Device} from "../../../App";
import useMediaQuery from '@mui/material/useMediaQuery';


const WrapModal = (props) => {
    const using_mob = React.useContext(Using_Mobile_Device);
    const h_w_650 = useMediaQuery('(max-height:650px)');
    useEffect(() => {

    }, []);

    let cls_trigger =props.class_trigger ?props.class_trigger : '' ;

    let d_classes = 'wrap_modal_d ';
    let mob_classes = ' wrap_modal_d';
    let class_for_curr_device = using_mob ? mob_classes : d_classes;
    let class_position_children = props.class_position_children ? props.class_position_children : '';
    let class_scroll =  h_w_650 ? 'hide_scroll' : '';

    let class_full_w = props.full_w ? props.full_w : '';
    return (

            <div
                className={`wrap_modal ${class_full_w} ${class_for_curr_device} ${class_position_children} ${class_scroll} ${cls_trigger}`}
            >
                {props.children}
            </div>

    );
};

export default WrapModal;
