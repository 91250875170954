import React, { useEffect, useCallback, useState } from "react";
import {glv, Opened_Modal, setlogos} from "../../../App";
import Wrap_Overlay from "../../wrap_components/overlay_wrappers/Wrap_Overlay";
import WrapModal from "../../wrap_components/modal_wrappers/Wrap_Modal";
import Title_Modal from "../../modal_components/Title_Modal";
import ActionsModalButtons from "../../modal_components/Actions_Buttons_Modal";
import AuthService from "../../../services/auth.service";

import ReqRenewPasswordForm from "./form/form_req_renew_password";
import {validateEmail} from "../../../helper_functions/helpers";
import {Notifications_History_Context} from "../../wrap_components/notification_wrapper/notifications_wrapper";

let template_report_form = {
 email: ''
}

const ContactModal = (props) => {
    const [showToast, close_all_notification] = React.useContext(Notifications_History_Context);
    const [opened_modal, set_opened_modal] = React.useContext(Opened_Modal);
    if (props.open) {
        set_opened_modal(true)
    }
    const [form, set_form] = React.useState(
        template_report_form
    );

    const [errors, set_errors] = React.useState(
        {
            email: [],
        }
    );
    const validate_ = React.useCallback((name, value)=>{
        let new_arr_errs = [];
        const callback_set = ()=>set_errors({...errors, [name]: new_arr_errs});

        switch (name) {
            case 'email': {

                if (!validateEmail(value)) {
                    new_arr_errs.push('emailValid')
                }
                return callback_set()
            }
        }
    }, [form])

    const handle_change_form =React.useCallback(
        (name, value)=>{
            validate_(name, value)
            set_form({...form, [name]:value})
        },
        [form]
    )

    const check_allow_submit =React.useCallback(
        ()=>{
            if(!form.email) {
                return false;
            }
            let has_some_error = errors.email.length;

            if (has_some_error) {
                return false
            }
            return true;
        },
        [form, errors]
    )
    const callback_toast = (text, code)=>{
        showToast(text, code);
    }

    const submit_request_renew_password = useCallback(async () => {
        if (!navigator.onLine) {
            props.no_internet()
            return;
        }
        await AuthService.resetPass(form.email).then((data) => {
            if (data.code === 401) {
                callback_toast(glv(`login.forgPassModal.${'alertError'}`), 401)
                set_opened_modal(false)
                props.close()
                setlogos("no register email", "try reset pass");
                return
            }
            callback_toast( glv(`login.forgPassModal.${'alertInfo'}`), data.code)
            set_opened_modal(false)
            props.close()
        }).catch((err) => {
            setlogos(err, "try reset pass");
        });
    }, [form, props.close]);

    return (
        <Wrap_Overlay
            open={props.open}
            hidden_body={true}
            handle_click_overlay={()=>{
                set_opened_modal(false)
                props.close()
            }}
            class_position_children={'overlay_children_position'}
        >
            <WrapModal>
                <Title_Modal
                    title_text={glv(`login.forgPassModal.${'modalTitle'}`)}
                />

                <ReqRenewPasswordForm
                    form={form}
                    on_change_form={(name, value)=>handle_change_form(name, value)}
                    errors={errors}
                />
                <ActionsModalButtons
                    disabled_acc_btn={
                        !check_allow_submit()
                    }
                    has_cancel={false}
                    has_accept={true}
                    text_accept_btn={glv(`login.forgPassModal.${'submitBtn'}`)}
                    style={{marginTop:30}}
                    accept_action={()=>{

                        submit_request_renew_password()
                    }}
                    cancel_action={()=>{
                        set_opened_modal(false)
                        props.close()
                    }}
                />
            </WrapModal>
        </Wrap_Overlay>
    );
};

export default ContactModal;

