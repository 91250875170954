import React, { useEffect, useCallback, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import {Notifications_History_Context} from "../../wrap_components/notification_wrapper/notifications_wrapper";
import Toast from "../../custom_components/Toast/Toast";
import {ChatIcon} from "../../../assets/svg_icons/svg_icons";
import HistoryNotificationModal from "../../modals/history_notification_modal/history_notification_modal";

const TestPage = (props) => {
    const [showToast, close_all_notification] = React.useContext(Notifications_History_Context);
    const [open_history_toasts_modal, set_open_history_toasts_modal] = React.useState(false);
    let history_notification = JSON.parse(sessionStorage.getItem('notifications'));
    return (
            <div >
                <HistoryNotificationModal
                    open={open_history_toasts_modal}
                    close={()=>{
                        set_open_history_toasts_modal(false)
                    }}
                />
                {
                    history_notification.length ?
                        <div
                            style={{marginLeft: '50%'}}
                            onClick={()=>{
                                set_open_history_toasts_modal(true)
                                close_all_notification()
                            }
                            }
                            className={'chat_icon_wrap'}>
                            <ChatIcon

                                class_name={'chat_icon'}/>
                        </div> : null
                }
                <button onClick={()=>{
                    showToast(
                        '200',
                        uuidv4()

                    )
                }}>test notifycation</button>

                <button onClick={()=>{
                    showToast('300',
                        uuidv4()+
                        uuidv4()+
                        uuidv4()+
                        uuidv4()+
                        uuidv4()
                    )
                }}>test notifycation info</button>
            </div>
    );
};

export default TestPage;
