import React, { useEffect, useRef, useState } from "react";
import {CloseIcon} from "../../../assets/svg_icons/svg_icons";


const Notification = (props) => {
    // const ref_notification = useRef(null);
    let is_reverse = props.is_reverse ? props.is_reverse : '';
    let cls_trigger = props.class_trigger ? props.class_trigger : '';



    return (
        <li
            // ref={ref_notification}
            className={`li_wrap_notification not_animation ${cls_trigger}`}
        >

            {
                props.index=== 0&&cls_trigger?null:
                    <div style={{height: props.fake_top_margin}}>

                    </div>
            }

            <div className={'wrap_container_notifications_modal'}>
                {/*{props.icon? props.icon : null}*/}
                <div className={`container_notification ${is_reverse} ${cls_trigger}`}
                     onClick={()=>{
                         if ( props.on_hide) {
                             props.on_hide()
                         }

                     }}
                >
                    <div className={'wrap_text_notification'}>
                        <span className={'text_notification'}>{props.text}</span>
                    </div>
                    {/*<div className={`wrap_code_time_notification ${cls_trigger}`}>*/}
                        <span className={'code_notification'}>{props.code}</span>
                        {
                            props.time ?
                                <span className={'time_notification'}>{props.time}</span> : null
                        }

                    {/*</div>*/}
                    {
                        props.not_close  ? null :
                            <div className={'wrap_close_icon_notification'}
                                 onClick={()=>{
                                     if (   props.on_close) {
                                         props.on_close()
                                     }
                                 }}
                            >
                                <CloseIcon
                                    width={'100%'}
                                    height={'100%'}
                                    class_name = {'close_icon_notification'}
                                />
                            </div>
                    }


                </div>
            </div>

        </li>
    );
};

export default Notification;
