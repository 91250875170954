import React, {useEffect, useCallback, useState} from "react";
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./router/router";
import * as actions from "./redux/actions/authActions/auth.actions";
import history from "./services/history";
import {useDispatch, useSelector} from 'react-redux';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import appInfo from '../package.json';
import NoNoNo from './components/pages/pub_components/toast';
import AuthService from "./services/auth.service";

import OfflineDialog from './components/modals/offline_modal/offlineModal';
import Cookies from 'js-cookie';
import moment from "moment";
import sha1 from 'sha1';
import {assocPath, dissocPath, drop, remove, pathOr, path} from "ramda";
import {detect_device} from "./helper_functions/helpers";
import AppVersion from "./components/custom_components/version_text";
import ConfirmCookiesModal from "./components/modals/confirm_cookies_modal/confirm_cookies_modal";
import NotificationWrapper from "./components/wrap_components/notification_wrapper/notifications_wrapper";
import CustomImageLoader from "react-custom-image-loader.";
import Backdrop from "@mui/material/Backdrop";

// Koнтексты

const version = appInfo.version;

export const Using_Mobile_Device = React.createContext();

export const Opened_Modal = React.createContext();



export let env_urls = null;

export let set_env_urls = (urls) => {
    env_urls = {...urls}
}

export let localization_tree = {}

export let test_domen = 'https://i.bregus.eu';

function validate_referal_code(code) {
    let hash_ = window.location.hash.replace(/^#/, '');
    let test_lan_num = /^[a-zA-Z0-9]+$/g.test(hash_);
    if (hash_ &&
        test_lan_num && hash_.length >= 4 && hash_.length <= 10) {
        return true
    }
    return false
}

export const logout = async (redir = true) => {
    let hash = await Cookies.get("hash_key", {domain: '.bregus.eu'});
    await AuthService.logout(hash);
    Cookies.remove("hash_key", {domain: '.bregus.eu'});
    if (redir) {
        window.location.href = "/";
    }

};

export let set_localization_tree = (tree) => {
    localization_tree = {...tree}
};


export const set_hidden_overflow_to_body = (status)=>{
    document.body.style.overflow = status;
}

export function glv(path_local, type = 'str', can_send_log = false) {
    // console.log(path_local)
    let parsed_path = path_local.split('.');
    let path_value = path(parsed_path, localization_tree);
    let error_r = false;

    if (path_value === undefined) {

        switch (type) {
            case 'arr':
                path_value = [];
                break;
            case  'obj':
                path_value = {};
                break;
            case  'str':
                path_value = path_local;
                break;
            default :
                path_value = "NaN";
        }

        error_r = true;

    } else {
        if (type === 'arr' && !Array.isArray(path_value)) {
            error_r = true;
            path_value = [];
        } else if (type === 'obj' &&  path_value.constructor !== Object) {
            error_r = true;
            path_value = {};
        } else if (type === 'str' && typeof path_value != 'string') {
            error_r = true;
            path_value = path_local;
        }

    }

    if (!can_send_log && error_r) setlogos(
        {description: 'по заданному пути нет значения', path: path_local, type: type},
        'ошибка при попытке получить языковое значение по заданному пути'
    );


    return path_value;
}

export const setlogos = (error, point) => {
    let login = Cookies.get("hash_key", {domain: ".bregus.eu"});
    let errforLog = window.constructor_error(moment().unix(), login ? login : "", point, error)
    let logos_ = localStorage.getItem("logos");
    // console.log(logos_)
    let logos = JSON.parse(logos_);

    logos.arr.push(errforLog);
    localStorage.setItem(
        "logos",
        JSON.stringify({key: sha1(logos.arr), arr: logos.arr})
    );
};

function UserException(message) {
    this.message = message;
    this.name = "Исключение, определённое пользователем";
}
window.slom_use = () => {
    setlogos('asdasfsdf', 'etutyutu')
    throw new UserException("Неверно указан номер месяца");

}


let test_notification = {
    id: 'test_notification',
    text: 'Test test test',
    code: '555',
    time: '12:30',
    expired: false
}

const App = (props) => {
    sessionStorage.setItem("test", "test_text")

    const dispatch = useDispatch();
    const is_loading = useSelector((state) => state.authReducer.is_loading);
    const [internetConnDi, setInternetConnDi] = useState(false);
    const setIsConfirm = useCallback(
        (status) => dispatch(actions.setIsConfirm(status)),
        [dispatch]
    );
    const setLangValues = useCallback(
        (langValues) => dispatch(actions.setLangValues(langValues)),
        [dispatch]
    );
    const setLangConfig = useCallback(
        (conf) => dispatch(actions.setLangConf(conf)),
        [dispatch]
    );
    const setOpenRedirectFrame = useCallback(
        (status) => dispatch(actions.setOpenRedirectFrame(status)),
        [dispatch]
    );
    const [isOnl, setIsOnl] = useState(navigator.onLine);
    const [achtung, setAchtung] = useState(false);
    const [opened_modal, set_opened_modal] = useState(false);
    const [show_confirm_cookies_modal, set_show_confirm_cookies_modal] = useState(false);

    const [first_script_loaded, set_first_script_loaded] = React.useState(false);
    const [langConf, setLangConf] = useState('');
    const [servInfo, setServInfo] = useState({});
    const localization = useSelector((state) => state.authReducer.lang_values);
    const language = useSelector((state) => state.authReducer.language);

    const [using_mob, set_using_mob] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const [offline_modal_show, set_offline_modal_show] = React.useState(false);

    const changeLanguage = useCallback(
        (lang) => dispatch(actions.setLanguage(lang)),
        [dispatch]
    );


    const checkLog = async () => {
        let login = await Cookies.get('hash_key', {domain: '.bregus.eu'});

        if (!login) {
            return
        }
        let res = await AuthService.getAccessedLinks(login);

        let path_loc = window.location.pathname;

        if ((res.code === 200 || res.code === 201) && Object.keys(res.info).length && path_loc !== '/page_info') {
            if (path_loc !== '/select_page') {
                window.location.href = '/page_info#authorizated'

            }
        }
        if ((res.code === 200 || res.code === 201) && Object.keys(res.info).length && window.location.pathname === '/select_page') {
            await dispatch(setOpenRedirectFrame(true));
        }

        AuthService.getInfo(window.innerWidth, window.innerHeight, login).then((res) => {
            if (!res?.server_info) {
                return
            }
            setServInfo(res.server_info);
        })
    }

    const upd_offline_status = ()=>{
        if(navigator.onLine) {
            set_offline_modal_show(false)
        } else {
            set_offline_modal_show(true)
        }
    }


    useEffect(() => {

        let path = window.location.pathname;
        if (path.length > 1 && path !== '/' && path !== '/page_info' && path !== '/register' && path !== '/select_page'&& path !== '/test_page') {
            window.location.href = '/page_info#404'
        }
    }, [])

    useEffect(() => {
        setTimeout(function run() {

            let logos = JSON.parse(localStorage.getItem('logos'));
            if (!logos) {
                localStorage.setItem('logos', JSON.stringify({key: '', arr: []}));
                return;
            }

            if (navigator.onLine && logos.key) {
                AuthService.logosErr(logos);
                localStorage.setItem('logos', JSON.stringify({key: '', arr: []}));
            }

            setTimeout(run, 10000);
        }, 1);
    }, []);

    useEffect(() => {

        window.addEventListener('online',  (e)=>{
            upd_offline_status()
        });
        window.addEventListener('offline', (e)=>{
            upd_offline_status()
        });

        let detect_dev = detect_device();
        set_using_mob(detect_dev);

        const LoadExternalScript = () => {
            const externalScript = document.createElement("script");
            externalScript.onerror = () => {
            };
            externalScript.async = true;
            document.body.appendChild(externalScript);
            externalScript.src = `https://bregus.eu/js_pugins/requestor.js`;
            externalScript.onload = () => {
                set_first_script_loaded(true)
            }
        };
        LoadExternalScript();
        fetch(`${test_domen}/json/conf.json`).then(data => data.json()).then((res) => {
            setAchtung(res.achtung);
            set_env_urls({url: res.url.api, apiKey: res.keys.apiKey})

            if (window.location.pathname === '/register' && validate_referal_code(window.location.hash)) {
                // let link_img = `https://api.bregus.eu/${env_urls.apiKey}/load/qr_partner?data=T12345678&black"/>`;
                // document.querySelector('meta[property="og:image"]').setAttribute("content", link_img);
            }
            checkLog();
        });

        fetch(`${test_domen}/json/lang/conf.json`).then(data => data.json()).then((res) => {

            let lang = Cookies.get('language', {domain: '.bregus.eu'});
            let isValidLang = Object.keys(res.language_arr).find((item) => item === lang);
            changeLanguage(lang && isValidLang ? lang : res.main_language);
            setLangConfig(res);
            setLangConf(res);

            let cookie_consent = Cookies.get('cookie_consent', {domain: '.bregus.eu'});

            if (cookie_consent) {
                setIsConfirm(true)
                Cookies.set('cookie_consent', 'true', {expires: 90, domain: '.bregus.eu', sameSite: 'Strict'})
            } else {

                set_show_confirm_cookies_modal(true)
                set_hidden_overflow_to_body('hidden')
            }
        });
        window.addEventListener("offline", function (e) {
            setIsOnl(false);
        })

        window.addEventListener("online", function (e) {
            setIsOnl(true)
        })

    }, [])

    useEffect(() => {
        if (langConf && language && first_script_loaded) {
            fetch(`${test_domen}${langConf.language_arr[language].language_url}`).then(data => data.json()).then((res) => {

                let tree = window.parser_lang_tree(res, dissocPath, assocPath);

                localization_tree = {...tree};
                setLangValues(tree);

            });
        }
    }, [language, langConf, first_script_loaded])

    return language && localization ? (
<NotificationWrapper>
         <Opened_Modal.Provider value={[opened_modal, set_opened_modal]}>
           <Using_Mobile_Device.Provider value={using_mob}>

            <BrowserRouter history={history}>
                {
                    is_loading ?
                    <Backdrop open={true} style={{zIndex: 1000}}>
                        <CustomImageLoader
                            image={`${process.env.PUBLIC_URL}/favicon.ico`}
                            isLoaded={true}
                            circle={false}
                            speed={2}
                            animationType={"pulse"}
                        />
                    </Backdrop> : null
                }
                <div style={{display: 'none'}}>{version}</div>
                <OfflineDialog
                    open={offline_modal_show}
                    close={()=>set_offline_modal_show(false)}
                />
                {
                    achtung ?
                        <NoNoNo/>
                        :
                        <AppRouter/>

                }

                {
                    internetConnDi ?
                        < OfflineDialog
                            open={internetConnDi}
                            close={()=>setInternetConnDi(!internetConnDi)}
                        />  : null
                }
                <AppVersion isOnl={isOnl} servInfo={servInfo}/>

                <Fade in={open}>
                    <Paper
                        style={open ? {
                            position: 'absolute', right: 20, bottom: 40,
                            background: "linear-gradient(0deg, #E3E9F7, #E3E9F7)",
                            boxShadow: "-9px -9px 24px rgba(255, 255, 255, 0.570094), 20px 22px 40px rgba(0, 0, 0, 0.0826595)",
                            borderRadius: 5,
                            padding: "3px 10px",

                        } : {display: 'none'}}
                    >
                        <span
                            style={{color: '#616074', fontSize: 12}}>{glv(`tooltips.${'helpPage'}`)}</span>

                    </Paper>

                </Fade>
                <ConfirmCookiesModal
                    open={show_confirm_cookies_modal}
                    close={()=> {
                        setIsConfirm(false)
                        set_show_confirm_cookies_modal(false)
                        set_hidden_overflow_to_body('auto')
                    }}
                    accept={()=>{
                        Cookies.set('cookie_consent', 'true', {
                            expires: 90,
                            domain: '.bregus.eu',
                            sameSite: 'Strict'
                        });

                        set_show_confirm_cookies_modal(false)
                        setIsConfirm(true);
                        set_hidden_overflow_to_body('auto')
                    }}
                />
            </BrowserRouter>
        </Using_Mobile_Device.Provider>
        </Opened_Modal.Provider>
</NotificationWrapper>
    ) : null;
};

export default App;
