import React from 'react';
import BoxCenter from "../../wrap_components/global_wrappers/main_wrapper";
import { useDispatch, useSelector } from "react-redux";
import {glv} from "../../../App";

const NoNoNo = () => {
  const localization = useSelector((state) => state.authReducer.lang_values);
    return (
    <BoxCenter>
    {/* <div style={{  margin: '0 auto' }}> */}
    <h2 style={{color:'#2F2A61', textAlign: 'center'}}>{
        glv(`achtung.achtungTexts.${'achtungTitle'}`)
}
 </h2>
      <p style={{color:'#2F2A61',  textAlign: 'center'}}>{
          glv(`achtung.achtungTexts.${'achtungText'}`)
      }
</p>
    {/* </div> */}
    </BoxCenter>  
  )};
export default NoNoNo;