import React, { useEffect, useCallback, useState } from "react";

const ContentDescription = (props) => {

    return (
        <div
            className={`wrap_consent_text`}
        >
            <p className={'consent_text'}>
                {
                    props.text
                }
            </p>
        </div>
    );
};

export default ContentDescription;
