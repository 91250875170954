import React from "react";

const PurpleButton = (props) => {
    let cust_style= props.style ? props.style : {};
    let cls_outer = props.cls_outer ? props.cls_outer : '';
    let cls_inner = props.cls_inner ? props.cls_inner : '';
    return (
        <div
            onClick={props.disabled ? ()=>{}:()=>props.on_click()}
            className={'purple_button_outer '+cls_outer }
            style={cust_style}
        >
            <div className={'purple_button_inner ' + cls_inner}>
                <span className={'purple_button_text'}>{props.text}</span>
            </div>
        </div>

    );
};

export default PurpleButton;
