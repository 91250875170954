import React from "react";
import {glv, Opened_Modal} from "../../../App";
import Wrap_Overlay from "../../wrap_components/overlay_wrappers/Wrap_Overlay";
import WrapModal from "../../wrap_components/modal_wrappers/Wrap_Modal";
import Title_Modal from "../../modal_components/Title_Modal";
import ContentSelectLangs from "./content/content_select_lang_modal";

const SelectLangModal = (props) => {
    const [opened_modal, set_opened_modal] = React.useContext(Opened_Modal);
    if (props.open) {
        set_opened_modal(true)
    }
    return (
        <Wrap_Overlay
            z_index={990}
            open={props.open}
            hidden_body={true}
            handle_click_overlay={()=>{
                set_opened_modal(false)
                props.close()
            }}
            class_position_children={'overlay_children_position'}
        >
            <WrapModal>
                <Title_Modal
                    title_style={{overflow:'hidden', whiteSpace:'nowrap', textOverflow: 'ellipsis'}}
                    title_text={glv(`select_lang_modal.title` )}
                />
                <ContentSelectLangs
                    close={()=> {
                        set_opened_modal(false);
                        props.close();
                    }}/>
            </WrapModal>
        </Wrap_Overlay>
    );
};

export default SelectLangModal;
